
.container {
  width: 100vw;
  background-color: #E7F0FF;
}
.content_container {
  position: relative;
  background-color: #E7F0FF;
  width: 828px;
  margin-left: auto;
  margin-right: auto;
  height: 9700px;
}

.title_text{
    font-family: "PingFang SC","微软雅黑", Helvetica, Arial, STXihei, "华文细黑", sans-serif;
    color: #0C0033;
    position: relative;
    font-size: 45px;
    font-weight: 600;
    text-align: center;
    width: 730px;
    left: 50px;
    position: relative;
    top: 300px;
}
.body_text{
    font-family: "PingFang SC","微软雅黑", Helvetica, Arial, STXihei, "华文细黑", sans-serif;
    color: #0C0033;
    position: relative;
    font-size: 30px;
    text-align: center;
    font-weight: 400;
    line-height: 1.6;
    width: 730px;
    left: 50px;
    top: 400px;
    position: relative;
}

.small_text{
    font-family: "PingFang SC", Helvetica, Arial, "Microsoft Yahei", "微软雅黑", STXihei, "华文细黑", sans-serif;
    color: #0C0033;
    opacity: 0.7;
    position: relative;
    font-size: 25px;
    text-align: center;
    width: 600px;
    left: 100px;
    top: 400px;
}
    
.screenshot {
    width: 700px;
    left: 60px;
    position: relative;
    top: 450px;
}
.screenshot img {
    max-width: 100%;
    max-height: 100%;
}
.sk_logo {
    width: 700px;
    height: 200px;
    left: 60px;
    top: 80px;
    position: absolute;
}
.sk_logo img {
    max-width: 28%;
    max-height: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

