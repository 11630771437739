.container {
  height: 850%;
  background-color: #181E57;
  width: 100vw;
}
.content_container {
  position: absolute;
  width: 1000px;
  height: auto;
  margin: 0 0 0 -500px;
  left: 50%;
  background-color: transparent;
  text-align: center;
  color: #FFFFFF;
  z-index: 999;
}
.header {
  padding: 0;
  top: 0px;
  position: fixed;
  background-color: rgba(24, 30, 87, 0.9);
}
.app_info {
   float: left;
   width: 20%;
   position: relative;
   cursor: pointer;
  .logo {
    height: 32px;
    margin: 5px 10px 10px 10px;
    left: 5px;
  }
  .name {
    position: relative;
    font-weight: 500;
    font-size: 16px;
    color: rgba(255, 255, 255, 1);
  }
}
.squirrel_menu {
  background-color: transparent;
  margin-left: 220px;
  margin-top: 3px;
  width: 790px;
  vertical-align: middle;
  text-align: left;

  :global {
    li.ant-menu-item {
      border-bottom: 2px solid transparent !important;
    }
    li.ant-menu-item a {
      font-size: 16px !important;
      padding: 5px 10px !important;
      width: 70px;
      text-align: center;
    }
    li.ant-menu-item.ant-menu-item-selected {
      background-color: transparent !important;
      border-bottom: 2px solid #AE6BE1 !important;
    }
    .ant-menu-item:hover {
      color: #AE6BE1 !important;
    }
  }
}

.language_switch_section {
  float: right;
  position: relative;
  top: -67px;
  right: 100px;
}

.language_switch {
  width: 60px;
  font-size: 15px;
  background-color: #AE6BE1;
}

.profile_section {
  //background-color: #f6f9fc;
}

.login_section_link {
  float: right;
  margin-top: -50px;
  margin-right: 20px;
  height: 32px;
  color: #AE6BE1;
  font-weight: 400;
  font-size: 16px;
}
.login_section_link:hover {
  color: #ffffff;
}


.login_section {
  float: right;
  margin-top: -50px;
  margin-right: 30px;
}

.login_section .logo_box {
  width: 70px;
  height: 70px;
  border-right: 1px solid #F4F4F5;
  justify-content: flex-start;
}

.login_section .logo {
  width: 37px;
  height: 37px;
  margin: 17px;
}

.login_section .photo {
  vertical-align: middle;
  margin-top: -15px;
  margin-left: 50px;
  background-color: #181e57;
  cursor: pointer;
}

.logo_email{
    height: 30px;
    font-weight: bold;
    color: #2a2f45;
    font-weight: 500;
    font-size: 14px;
}
.logo_list_line{
    height: 1px;
    border-bottom: 1px solid #e3e8ee;
    margin-bottom: 10px;
}
.logo_list_item{
    cursor: pointer;
    height: 30px;
    line-height: 30px;
    color: #556cd6;
}
.logo_list_item:hover{
    color: #2a2f45;
    font-size: 14px;
    //background-color: #f7fafc;
}


.website_title h1 {
  padding-top: 150px;
  font-weight: 500;
  color: #FFFFFF;
  font-size: 50px;
}
.website_title h1.no_padding{
  margin-top: -180px;
}
.website_title h3 {
  margin-top: 20px;
  font-weight: 400;
  color: #FFFFFF;
  font-size: 22px;
}
.app_demo_image {
  margin-top: 20px;
  background: url("../images/app-demo-image-new.png") no-repeat center 0;
  background-size:100% 100%;
  height: 660px;
  width: 100%;
  margin-left: 0;
}
.vpn_features_image_EN {
  margin-top: 50px;
  background: url("../images/vpn-services-features.png") no-repeat center 0;
  background-size:100% 100%;
  height: 467px;
  width: 950px;
  margin-left: 2.5%;
}
.vpn_features_image_CN {
  margin-top: 50px;
  background: url("../images/vpn-services-CN.png") no-repeat center 0;
  background-size:100% 100%;
  height: 450px;
  width: 950px;
  margin-left: 2.5%;
}
.btn_buy{
  width: 200px;
  height: 44px;
  margin: 60px 20px;
  background-color: #AE6BE1;
  color: #FFFFFF;
  font-size: 17px;
  font-weight: 500;
  border: none;
  border-radius: 90px;
  outline: none;
  cursor: pointer;
}
.btn_buy:hover {
  background-color: rgba(166, 124, 247, 0.9);
}
.btn_download {
  width: 300px;
  height: 50px;
  margin: 8px 20px;
  background-color: #181E57;
  color: #ffffff;
  font-size: 17px;
  font-weight: 500;
  border-radius: 90px;
  border: none;
  outline: none;
  cursor: pointer;
  img {
    max-height: 25px;
    margin: 0 10px 0 0;
  }
  .hidden_on_desktop {
    display: none;
  }
}
.btn_download:hover {
  background-color: rgba(62, 66, 109, 1.0);
}
.download_panel {
  background: #ffffff;
  padding: 50px 100px;
  margin: 50px 0px;
}
.download_section h1 {
  padding: 70px 20px 10px 20px;
  font-weight: 500;
  color: #FFFFFF;
  font-size: 50px;
}
.download_section h2 {
  padding-top: 50px;
  font-weight: 500;
  color: #181E57;
  font-size: 30px;
}
.download_section h4 {
  padding-top: 50px;
  font-weight: 500;
  color: #AE6BE1;
  font-size: 30px;
}
.download_section p {
  padding: 0px 170px;
  font-weight: 500;
  color: #181E57;
  font-size: 18px;
  margin-bottom: 0px;
}
.download_section p.light {
  color: #afafaf;
  font-weight: 400;
  font-size: 14px;
}
.download_section a.link {
  color: #AE6BE1;
  font-weight: 400;
  font-size: 14px;
  text-decoration: underline;
}
.qr_image {
  margin: 10px 20px 30px 20px;
  align: center;
  img {
    max-height: 200px;
  }
}

.buy_section {
  padding: 50px 0px;
}
.buy_section .pkg_item {
  width: 305px;
  height: 342px;
  margin-left: 20px;
}
.buy_section .pkg_item_large {
  width: 305px;
  height: 395px;
  margin-left: 20px;
}
.buy_section .pkg_item:hover{
  border: 2px solid dodgerblue;
  cursor: pointer;
}
.buy_section .pkg_item_large:hover{
  border: 2px solid dodgerblue;
  cursor: pointer;
}
.buy_section h1 {
  padding: 40px 20px;
  font-weight: 500;
  color: #FFFFFF;
  font-size: 50px;
}
.technology_section {
  padding: 50px 50px;
}
.technology_section h1 {
  padding-top: 20px;
  font-weight: 500;
  color: #FFFFFF;
  font-size: 50px;
}
.technology_section h3 {
  padding: 10px 10px 0px 10px;
  font-weight: 400;
  color: #FFFFFF;
  font-size: 18px;
  //text-align: left;
}

.support_section {
  width: 100%;
  height: 690px;
  padding-top: 50px;
}
.support_section h1 {
  padding-top: 80px;
  margin-bottom: 5px;
  font-weight: 500;
  color: #181E57;
  font-size: 50px;
}
.support_section h2 {
  font-weight: 500;
  margin-bottom: 5px;
  color: #afafaf;
  font-size: 20px;
}
.support_section .support_details {
  padding: 30px 0px 0px 100px;
  margin-bottom: 0;
  font-weight: 400;
  color: #181E57;
  font-size: 16px;
  text-align: left;
}

.support_section .box_left{
  width: 520px;
  height: 100%;
  float: left;
  background-color: #ffffff;
}
.support_section .box_right{
  overflow: hidden;
  background: url("../images/support.png") no-repeat;
  background-size: 100% 100%;
  height: 100%;
}
.footer p {
    margin-bottom: 5px;
    font-size: 12px;
}
.footer a {
    color: rgba(0, 0, 0, 0.65);
}
.footer a:hover {
    color: rgba(166, 124, 247, 0.9);
}


/* ipad */
@media only screen and (max-width: 768px) {
    .container {
      height: 890%;
    }
    .content_container {
      width: 760px;
      margin: 0 0 0 -380px;
    }
    .header {
      width: 100%;
      height: 55px;
    }
    .app_info {
       width: 30%;
       height: 55px;
      .logo {
        margin: 0px 10px 15px 0px;
      }
      .name {
        bottom: 5px;
      }
    }
    .squirrel_menu {
      display: none;
      margin-left: 0px;
    }
    .language_switch_section {
      top: -7px;
      right: 45px;
    }
    .login_section_link {
      top: 9px;
      margin-top: 0;
      margin-right: 0;
      right: 40px;
    }
    .login_section {
      top: 9px;
      margin-top: 0;
      margin-right: 0;
      right: 40px;
    }
    .app_demo_image {
      height: 508px;
    }
    .download_section p {
      padding: 0px 50px;
    }
    .vpn_features_image_EN {
      margin-left: 0;
      width: 100%;
      height: 360px;
    }
    .vpn_features_image_CN {
      margin-left: 0;
      width: 100%;
      height: 360px;
    }
    .buy_section .pkg_item {
      width: 220px;
      height: 245px;
    }
    .buy_section .pkg_item_large {
      width: 220px;
      height: 275px;
    }
    .support_section {
      height: 620px;
    }
    .support_section .box_left{
      width: 440px;
    }
    .support_section .box_right{
      background-size: 120% 105%;
    }
    
    .support_section h1 {
      padding-top: 60px;
      padding-bottom: 10px;
      font-size: 40px;
    }
    .support_section h2 {
      font-size: 18px;
    }
    .support_section .support_details {
      padding: 20px 0px 10px 60px;
      font-size: 16px;
    }
    
}

/* mobile app */
@media only screen and (max-width: 414px) {
    .container {
      height: 650%;
    }
    .content_container {
      width: 380px;
      margin: 0 0 0 -190px;
    }
    .header {
      width: 100%;
      height: 55px;
    }
    .app_info {
       width: 40%;
       height: 55px;
      .logo {
        height: 28px;
        margin: 0px 10px 12px 0px;
      }
      .name {
        bottom: 5px;
        font-weight: 400;
        font-size: 14px;
      }
    }
    .squirrel_menu {
      display: none;
      margin-left: 0px;
      :global {
        li.ant-menu-item a {
          font-size: 13px !important;
          padding: 0px 0px !important;
          width: 35px;
          text-align: left;
        }
        
        
      }
    }
    .hidden_on_mobile {
      display: none;
    }
    .language_switch_section {
      top: -7px;
      right: 30px;
    }
    .language_switch {
      width: 60px;
      font-size: 12px;
    }
    .login_section_link {
      top: 10px;
      margin-top: 0;
      margin-right: 0;
      right: 15px;
      font-size: 14px;
    }
    .login_section {
      top: 10px;
      margin-top: 0;
      margin-right: 0;
      right: 15px;
    }
    .website_title h1 {
      font-size: 30px;
      font-weight: 400;
      line-height: 50px;
    }
    .website_title h3 {
      font-size: 15px;
      font-weight: 300;
      line-height: 30px;
      padding: 0px 20px;
    }
    .btn_buy {
      width: 100px;
      margin: 20px 10px;
      font-size: 14px;
      height: 35px;
      font-weight: 400px;
    }
    .btn_download {
      width: 200px;
      height: 35px;
      margin: 5px 5px;
      font-size: 14px;
      font-weight: 400;
      padding: 5px;
      .hidden_on_desktop {
        display: inline-block;
      }
    }
    .app_demo_image {
      height: 250px;
    }
    .technology_section {
      padding: 10px 10px;
    }
    .technology_section h1 {
      font-weight: 400;
      font-size: 30px;
      margin: 20px 5px 0 5px;
    }
    .technology_section h3 {
      padding: 0px 15px 15px 10px;
      font-weight: 300;
      font-size: 15px;
    }
    .download_panel {
      padding: 40px 0px;
      margin: 10px 10px 20px 10px;
    }
    .download_section h1 {
      font-weight: 400;
      font-size: 30px;
      margin: 0px 5px 20px 5px;
    }
    .download_section h2 {
      padding-top: 0px;
      font-weight: 400;
      font-size: 20px;
    }
    .download_section h4 {
      padding-top: 20px;
      font-weight: 400;
      color: #AE6BE1;
      font-size: 20px;
    }
    .download_section p {
      font-weight: 400;
      font-size: 14px;
      padding: 0px 15px;
    }
    .download_section p.light {
      font-size: 12px;
    }
    .download_section a.link {
      font-size: 12px;
    }
    .qr_image {
      display: none;
    }
    .vpn_features_image_EN {
      margin-left: 0;
      width: 100%;
      height: 200px;
    }
    .vpn_features_image_CN {
      margin-left: 0;
      width: 100%;
      height: 200px;
    }
    .buy_section h1 {
      font-weight: 400;
      font-size: 30px;
    }
    .buy_section .pkg_item {
      width: 200px;
      height: 220px;
      margin: 0 0 10px 0;
    }
    .buy_section .pkg_item_large {
      width: 200px;
      height: 250px;
      margin: 0 0 10px 0;
    }
    .support_section .box_left{
      width: 100%;
      height: 560px;//100%;
    }
    .support_section .box_right{
      background: url("../images/support-image.png") no-repeat;
      background-size: 100% 100%;
      height: 300px;
      width: 100%;
    }
    .support_section h1 {
      padding-top: 40px;
      padding-bottom: 0px;
      font-weight: 500;
      font-size: 35px;
    }
    .support_section h2 {
      font-size: 17px;
    }
    .support_section .support_details {
      padding: 20px 0px 10px 50px;
      font-size: 15px;
    }
    .footer {
      margin: 250px 0 0 0;
    }

    
}
