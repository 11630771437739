.container {
  max-width: 750px;
  margin: auto;
  padding-bottom: 50px;
  background-color: #Ffffff;

  .form_center{
    margin: 0 30px 0 30px;
  }

  .account_center{
    padding-top: 50px;
    text-align: center;
  }

  .account_center .step_image {
    margin: 40px auto 0 auto;
    border: 2px solid #090B34;
    padding: 10px;
    border-radius: 5px;
  }

  .step_desc {
    margin: 30px auto;
    font-size: 16px;
    color: #090B34;
    line-height: 30px;
    width: 80%;
    font-family: "PingFang SC";
  }

  .step_desc_table {
    text-align: left;
    border-collapse: collapse;
    border-spacing: 0;
    text-overflow: ellipsis;
    width: 100%;
  }

  .language_switch_section {
    float: right;
    position: relative;
    top: 50px;
    right: 70px;
  }

  .language_switch {
    width: 60px;
    font-size: 16px;
    background-color: #AE6BE1;
  }

  .wallet_address{
    margin-top: 10px;
    color: #7686a4;
    font-size: 12px;
  }

  .btn_copy {
    width: 100px;
    margin-top: 10px;
  }

  .copy_button {
    margin-left: 30px;
    width: 50px;
    height: 22px;
    line-height: 20px;
    border: 1px solid #6179f8;
    background-color: #edf0ff;
    color: #6179f8;
    font-size: 12px;
    border-radius: 90px;
    outline: none;
    cursor: pointer;
  }


  .payment_tip{
    margin: 20px auto;
    width: 80%;
  }

  .notice_title {
    font-size: 14px;
    font-weight: 700;
    color: #16325c;
    line-height: 26px;
  }
  .notice_content {
    font-size: 14px;
    color: #090B34;
    line-height: 26px;
    font-weight: 400;
  }

  .form_button_row {
    margin-top: 5px;
    text-align: center;
  }

  .form_button {
    width: 200px;
    height: 39px;
    background-color: #6179f8;
    color: #fff;
    font-weight: 700;
    border: none;
    border-radius: 90px;
    outline: none;
    cursor: pointer;
  }

  .link_back_div {
    margin-top: 5px;
    text-align: center;
  }

  .link_back {
    font-size: 15px;
    color: #637bfd;
    border-bottom: 1px solid #637bfd;
    padding-bottom: 2px;
  }
}


/* mobile app */
@media only screen and (max-width: 414px) {

  .container {
    .step_desc {
      margin: 20px auto;
      font-size: 14px;
      width: 90%;
    }
    .payment_address {
      max-width: 200px;
      text-overflow: ellipsis;
      overflow: scroll;
    }

  }
}
