.container {
  max-width: 1024px;
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #fff;

  .country_name {
    color: #0C0033;
    fontSize: 16
  }

  .share_link {
    color: #4c596d;
    text-decoration: underline;
  }

  .space {
    height: 20px;
  }

  .split_line {
    margin-top: 10px;
    margin-bottom: 10px;
    height: 1px;
    background-color: #eaebed;
  }

}


.qrcode_view {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.qrcode {
  border: 2px solid #090B34;
  padding: 10px;
  border-radius: 5px;
}
