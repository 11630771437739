.login {
  max-width: 750px;
  margin: 0 auto;
  text-align: center;
  background-color: #fff;

  .logo_image {
    width: 40px;
    margin: 20px auto 0 auto;
  }

  .logo_title {
    margin-top: 15px;
    font-size: 18px;
    font-weight: 500;
    color: #16325c;
  }

  .form_item {
    margin: 20px 30px 0 30px;
  }

  .form_input {
    width: 100%;
    height: 39px;
    outline: none;
    border-radius: 7px;
    font-size: 15px;
  }

  .form_input:focus {
    background-color: #F7FAFE;
  }

  .form_button_row {
    margin-top: 25px;
  }

  .form_button {
    width: 200px;
    height: 39px;
    background-color: #6179f8;
    color: #fff;
    font-weight: 700;
    border: none;
    border-radius: 90px;
    outline: none;
    cursor: pointer;
  }

  .form_validation_input {
    width: 70%;
    height: 39px;
    border-right: none;
    border-radius: 7px 0px 0px 7px;
    font-size: 15px;
    vertical-align: middle;
  }

  .form_validation_button {
    width: 30%;
    height: 39px;
    background-color: #6179f8;
    color: #fff;
    font-weight: 700;
    border-radius: 0px 7px 7px 0px;
    outline: none;
    cursor: pointer;
    vertical-align: middle;
    border: none;
  }

  .link_download{
    margin-top: 5px;
    font-size: 12px;
    color: #16325c;
    cursor: pointer;
  }

  .message_box {
    width: 325px;
    margin-top: 10px;
  }

}

.qrcode_box {
  width: 247.5px;
  height: 277px;
  background-image: url("../../images/bg_qrcode.png");
  background-size: cover;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.qrcode_core {
  margin-top: 80px;
}
