.modal_close {
  color: #637bfd;
  font-size: 22px;
  text-align: right;
  margin-top: 0px;
  margin-right: 5px;
  background-color: #f6f9fc;
}

.container {
  max-width: 750px;
  padding: 30px 20px 50px 20px;
  text-align: center;
  background-color: #f6f9fc;
  border-radius: 7px;
}

.err_message {
  color: #c23d4b;
  text-align: left;
  margin-top: 15px;
}

.payment_button {
  width: 100%;
  height: 39px;
  background-color: #6772e5;
  color: #fff;
  font-weight: 700;
  border: none;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  margin-top: 20px;
  //box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #6772e5;
}
.payment_button:hover {
  background-color : rgba(97, 121, 248, 0.85);
  color: #fff;
}



/**
* Shows how you can use CSS to style your Element's container.
*/
.MyCardElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.MyCardElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.MyCardElement--invalid {
  border-color: #fa755a;
}

.MyCardElement--webkit-autofill {
  background-color: #fefde5 !important;
}
