.container {
  max-width: 750px;
  margin: 20px auto 50px auto;
  text-align: center;
  border-radius: 7px;

  .hide_hip {
    display: none;
  }
  .show_hip {
    display: block;
  }
  .chain_radios_hidden {
    display: none;
  }
  .chain_radios_show {
    display: block;
    margin: -10px auto 20px auto;
  }

  .product_title {
    font-weight: 500;
    color: #181E57;
    font-size: 25px;
  }
  .coin_title {
    font-weight: 500;
    color: #181E57;
    font-size: 22px;
    //text-align: left;
  }
  .coin_main_title {
    font-weight: 500;
    color: #181E57;
    //text-align: left;
    font-size: 16px;
  }
  .coin_item_list {
    width: 170px; /* to edit: edit the width of total elements */
    margin: 30px auto 10px auto;
    height: 100px;
  }
  .coin_item_list_narrow {
    width: 90px; /* to edit: edit the width of total elements */
    margin: 30px auto 10px auto;
    height: 100px;
  }


  p.link {
    margin-top: 8px;
  }
  p.link a {
    color: #AE6BE1;
    font-weight: 400;
    font-size: 14px;
    text-decoration: underline;
  }
  .coin_item {
    width: 70px;
    height: 80px;
    border: 2px solid #D7D7D7;
    text-align: center;
    margin-left: 10px;
    float: left;
    border-radius: 5px;
  }
  .coin_item:hover {
    width: 70px;
    height: 80px;
    border: 2px solid #5F7BF4;
    text-align: center;
    cursor: pointer;
    background-color: #d2d9ff;
    margin-left: 10px;
    float: left;
    border-radius: 5px;
  }
  .coin_item_selected {
    width: 70px;
    height: 80px;
    border: 2px solid #5F7BF4;
    cursor: pointer;
    background-color: #d2d9ff;
    text-align: center;
    margin-left: 10px;
    float: left;
    border-radius: 5px;
  }
  .coin_item_logo {
    width: 40px;
    margin-top: 8px;
  }
  .coin_item_name {
    font-size: 14px;
    text-align: center;
    font-weight: 500;
    margin-top: 5px;
  }
  .coins_main {
    width: 275px;
    margin-top: 20px;
    margin-bottom: 40px;
    //text-align: left;
  }
  .product_details {
    padding-left: 150px;
    line-height: 25px;
    text-align: left;
    padding-top: 20px;
    font-weight: 400;
    color: #3E426D;
    font-size: 15px;
  }
  .product_details_en {
    padding-left: 115px;
    line-height: 25px;
    text-align: left;
    padding-top: 20px;
    font-weight: 350;
    color: #3E426D;
    font-size: 15px;
  }
  .select_method {
    padding-top: 20px;
    font-weight: 350;
    color: #3E426D;
    font-size: 15px;
  }
  .product_price {
    text-align: center;
    height: 70px;
  }
  .product_price .product_price_text {
    font-weight: 400;
    color: #181E57;
    font-size: 15px;
    position: relative;
    top: -96px;
  }
  .product_price .product_price_text .text_total {
    font-weight: 500;
    margin-left: 100px;
  }
  .product_price_box {
    width: 350px;
    height: 50px;
    border-radius: 100px;
    border: 2px solid #C0C9FC;
    display: inline-block;
  }
  .product_price_box_filled {
    width: 180px;
    height: 50px;
    border-radius: 100px;
    background-color: #C0C9FC;
    display: inline-block;
    top: -55px;
    left: 85px;
    position: relative;
  }
  .payment_method_item {
    width: 200px;
    height: 50px;
    background-color: #6179f8;
    color: #fff;
    font-weight: 700;
    border: none;
    border-radius: 90px;
    outline: none;
    cursor: pointer;
  }
  .payment_coin_button {
    height: 40px;
    padding: 0 20px 0 20px;
    background-color: #6179f8;
    color: #fff;
    font-weight: 700;
    border: none;
    border-radius: 7px;
    outline: none;
    cursor: pointer;
  }
  .payment_coin_button_disabled {
    height: 40px;
    padding: 0 20px 0 20px;
    background-color: #d2d9ff;
    color: #fff;
    font-weight: 700;
    border: none;
    border-radius: 7px;
    outline: none;
    cursor: pointer;
  }

  .payment_method_item:hover {
    background-color : rgba(97, 121, 248, 0.85);
  }

  /* mobile app */
  @media only screen and (max-width: 414px) {
    .product_price_box {
      width: 300px;
    }
    .product_price_box_filled {
      width: 160px;
      left: 70px;
    }
    .product_details {
      padding-left: 70px;
    }
    .product_details_en {
      padding-left: 30px;
    }
    .product_price .product_price_text {
      left: 5px;
    }
    .product_price .product_price_text .text_total {
      margin-left: 70px;
    }
  }
}
